import React, { useState, useEffect } from "react";
import request from "request"

import { fromPairs } from "lodash"

import Table from "Table"
import Button from "Button"
import Select from "Select"
import Flash from "flash"

const Terminals = ({venue, terminals}) => {
  const [addTerminalOpen, setAddTerminalOpen] = useState(false)
  const [cardReaders, setCardReaders] = useState([])
  const [stores, setStores] = useState([])

  const [selectedStore, setSelectedStore] = useState(null)
  const [selectedCardReader, setSelectedCardReader] = useState(null)

  const fetchCardReaders = () => {
    const req = request.get("card_readers", { params: { venueId: venue.id }})
    req.then(({data}) => setCardReaders(data.cardReaders))
  }

  const fetchStores = () => {
    const req = request.get("stores", { params: { venueIdEq: venue.id }})
    req.then(({data}) => setStores(data.stores))
  }

  useEffect(fetchCardReaders, [])
  useEffect(fetchStores, [])

  const clearState = () => {
    setSelectedStore(null)
    setSelectedCardReader(null)
    setAddTerminalOpen(false)
  }

  const createTerminal = () => {
    const req = request.post("terminals", { terminal: { storeId: selectedStore, internalCardReaderId: selectedCardReader }})
    req.then(({data}) => {
      clearState()
    })
    req.catch(() => {
      Flash.broadcast("error", "That card reader is already assigned. Please try again")
    })
  }

  return(
    <div>
      <Table
        headers={TERMINAL_HEADERS}
        formatters={TERMINAL_FORMATTERS}
        rows={terminals}
        actions={
          <Button text="Add Terminal" onClick={() => setAddTerminalOpen(true)} />
        }
      />
      { addTerminalOpen && <div>
        <Select
          prompt={"Select a Store"}
          options={fromPairs(stores.map((s) => [ s.id, s.name ]))}
          onChange={setSelectedStore}
          value={selectedStore}
        />

        <Select
          prompt={"Select a Card Reader"}
          options={fromPairs(cardReaders.map((cr) => [ cr.id, cr.serialNumber ]))}
          onChange={setSelectedCardReader}
          value={selectedCardReader}
        />

        <Button
          text="Create Terminal"
          disabled={!selectedStore || !selectedCardReader}
          onClick={createTerminal}
        />
      </div>}
    </div>
  )
}

const TERMINAL_HEADERS = {
  number: "",
  store: "Store",
  authCode: "Auth Code",
  printer: "Printer",
  delete: "",
}

const TERMINAL_FORMATTERS = {
  number: (t) => `#${t.number}`,
  store: (t) => t.store.name,
  delete: (t) => <Button text="X" color="red" />,
  printer: (t) => t.hasPrinter ? "Has Printer" : "No Printer",
}

export default Terminals
